import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import React from "react";

const Number = styled.div`
  background: linear-gradient(
    ${(props) => props.rotate}deg,
    rgba(105, 31, 131) 0%,
    rgba(189, 18, 30) 100%
  );
  color: white;
  font-size: 3em;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
`;

const Step = ({ number, title, description }) => (
  <Box mb={4}>
    <Flex alignItems="center" flexWrap="wrap">
      <Flex width={[1, 1 / 4]} justifyContent="center" mb={[4, 0]}>
        <Number rotate={number * 20 + 90}>{number}</Number>
      </Flex>
      <Box width={[1, 3 / 4]}>
        <strong>{title}</strong>
        <br />
        {description}
      </Box>
    </Flex>
  </Box>
);

export default Step;
